
.social-media{
  flex: 0 0 80px;
  width: 100%;
  height: 80px;
  padding-block-start: 24px;
  text-align: end;
  padding-inline-start: 16px;
  padding-inline-end: max(env(safe-area-inset-right),16px);
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  
  @media (width > 812px) and (height < 429px), (width > 991px) and (height > 430px) {
    justify-content: flex-end;
    width: auto;
  }

  @media (width >= 992px) {
    padding-inline-start: 96px;
    padding-inline-end: 96px;
    padding-block-end: calc(140px - var(--logo-height));
    flex: 0 0  140px;
    height: 140px;
  }

  & ul {
    display: inline-flex;
    flex-flow: row nowrap;
    gap: 16px;
    padding: 0;

    & li{
      display: inline-block;
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.4rem;
      list-style: none;

      &::before{
        content: none;
      }

      & a {
        color: var(--social-media-icon-color);

        &::after{
          color: var(--social-media-icon-color);
          text-decoration: none;
        }
      }
    }
  }
}

.social-media-page{
  height: auto;
  flex: 0 0 auto;
  padding-inline-start: 0;
  padding-inline-end: 0;

  & ul li a {
    color: var(--social-media-icon-color-detailpage);

    &::after{
      color: var(--social-media-icon-color-detailpage);
      text-decoration: none;
    }
  }
}
