
.footer{
  border-block-start: 1px solid var(--neutral-200);
  width: 100%;
  padding-block-start: 16px;
  padding-block-end: 24px;
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;

  @media (width >= 992px) {
    flex-flow: row nowrap;
    padding-block-start: 16px;
    margin-block-start: 24px;
  }

  & p {
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.4rem;
    color: var(--footer-text-color);
    margin-block-end: 0;
  }

  & ul {
    display: flex;
    flex-flow: column nowrap;
    gap: 16px;
    padding: 0;
    
    @media (width >= 992px) {
      flex-flow: row nowrap;
    }

    & li{
      display: block;
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.4rem;
      color: var(--neutral-600);
      list-style: none;

      @media (width >= 992px) {
        display: inline-block;
      }

      &::before{
        content: none;
      }

      & a {
        color: var(--footer-text-color);
        text-decoration: inherit;

        &:visited{
          text-decoration: none;
        }

        &:hover{
          text-decoration: underline;
        }

        &:active{
          opacity: 0.8;
        }
      }
    }
  }
}