.container{
  height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: stretch;
  gap: 80px;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex: 0 0 56px;
  padding-inline-start: max(env(safe-area-inset-left),16px);
  padding-inline-end: max(env(safe-area-inset-right),16px);

  @media (width >= 992px) {
    padding-inline-start: 96px;
    padding-inline-end: 96px;
  }

  @media (width > 812px) and (height < 429px), (width > 991px) and (height > 430px) {    flex-flow: row nowrap;
  }
}


.content{
  flex: auto;
  grid-column: 1 /-1;
  text-align: start;

  @media (width > 812px) and (height < 429px), (width > 991px) and (height > 430px) {    grid-column: 3 /-3;
  }

  & h2{
    margin-block-end: 16px;
  }

  & p, & ul, & ol{
    &:not(:last-child){
      margin-block-end: 16px;
    }
  }
}

.intro{
  grid-column: 1 /-1;

  @media (width > 812px) and (height < 429px), (width > 991px) and (height > 430px) {    grid-column: 4 /-4;
  }

  & h1{
    margin-block-end: 8px;
  }

  & p:not(:last-child){
    margin-block-end: 24px;
  }
}

.email{
  color: var(--neutral-600);
  display:inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;

  & a {
    color: var(--neutral-600);

  }
}

.button{
  &:not(:last-child){
    margin-block-end: 16px;
  }

  & a {
    display: inline-flex;
  }
}

.intro-grid{
  margin-block-end: 80px;
}

.content-grid{
  margin-block-end: 24px;
}

.update{
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: var(--neutral-600);
}