.wrapper {
  display: flex;
  height: 100%;
  width: 100%;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding-inline-start: max(env(safe-area-inset-left),16px);
  padding-inline-end: 16px;
  padding-block-start: 40px;
  
  @media (width > 812px) and (height < 429px), (width > 991px) and (height > 430px) {    padding-block-start: 0;
  }

  @media (width >= 992px) {
    padding-inline-start: 96px;
    padding-inline-end: 96px;
  }

}


.header{
  flex: 0 0 54px;
  width: 100%;
  padding-block-start: 24px;

  & div{
    height: 30px;
    width: 60px;
  }
  
  & a{
    display:inline-block;
    transform-origin: center center;
    height: 30px;
    width: 60px;
  }

  & img {
    height: 30px;
    width: 60px;
    object-fit: contain;
    object-position: center left;
  }
}

.content-wrapper {
  display: grid;
  width: 100%;
  flex: 1;

  @media (width > 812px) and (height < 429px), (width > 991px) and (height > 430px) {    height: 100%;
    place-items: center;
  }
}


.container {
  width: 100%;
}

.heading{
  display: flex;
  flex-direction: column;
  margin-block-end: 8px;
  
  & [role="doc-subtitle"] { 
    order: -1;
    margin-block-end: 8px;
  }

  & + p{
    margin-block-end: 24px;
  }
}

.buttons{
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;
  padding-block-end: 40px;
  
  @media (width > 812px) and (height < 429px), (width > 991px) and (height > 430px) {    padding-block-start: 0;
  }
  
  @media (width > 992px) {
    flex-flow: row nowrap;
  }
}

.footer{
  flex: 0 0 54px;
}

.button-wrapper{
  width: auto;
  display: inline-block;

}