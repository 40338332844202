:root{
  /*
    Globals
  */

  /*
    Colors
  */
  --neutral-100: #fff;
  --neutral-200: #f0f0f0;
  --neutral-300: #BEBEC4;
  --neutral-600: #6C6C74;
  --neutral-700: #4E4E55;
  --neutral-900: #000;
  --brand-100: #F0B43C;
  --brand-200: #D79A1D;
  --brand-500: #286EB4;
  --brand-600: #1F4F8A;
  --brand-900: #0C1F36;
  --error-500: #AD351F;

  /*
  Gradients
  */
  --gradient-light: linear-gradient(180deg, #FFF 0%, #D3D0D5 100%);
  --gradient-dark: linear-gradient(180deg, #000 0%, rgb(0 0 0 / 72%) 100%);

  /*
    Shadows
  */
  --shadow-elevation-100: 0px 2px 16px -8px rgb(27 32 39 / 16%), inset 0px 0px 0px 0.5px rgb(27 32 39 / 10%);
  --shadow-elevation-200: 0px 2px 16px -8px rgb(27 32 39 / 16%), inset 0px 0px 0px 0.5px rgb(27 32 39 / 10%);

  /* 
    Specific colors
  */
  --text-color-default: var(--neutral-700);
  --text-color-accent: var(--brand-500);

  /*
    Fonts
  */
  --heading-font-family: "Bitterbrush";
  --body-text-font-family: "Nunito";

  /*
    Generic
  */
  --page-background-color: var(--neutral-100);

  /* 
  HOME - left column
  */

  --button-text-color: var(--neutral-100);
  --button-icon-color: var(--neutral-100);
  --button-bg-color: var(--brand-500);
  --button-gradient: var(--gradient-dark);
  --button-border-radius: 12px;
  --logo-height: 40px;

  /* 
  HOME - right column
  */

  --social-media-icon-color: var(--neutral-100);
  --social-media-bg-color: var(--brand-500);

  /* 
  DETAIL PAGE - right column
  */

  --social-media-icon-color-detailpage: var(--neutral-900);
  --social-media-bg-color-detailpage: var(--neutral-200);

  /* 
  in case of a solid color background:
  --product-shot-background: #ff1100;
  */
  
  /* in case of a gradient background:
   --product-shot-background: radial-gradient(100% 100% at 41.46% 100%, rgb(208 195 243 / 50%) 14.62%, rgb(92 85 165 / 50%) 63.14%, rgb(55 49 114 / 50%) 100%), #5C55A5;
  /*

  /* 
  in case of a image background
  --product-shot-background: #ff1100;
  --product-shot-bg-position: bottom center; 
  --product-shot-bg-size: 100% auto; 
  */;
  --product-shot-background: #D79A1D;
  --product-shot-bg-position: center center; 
  --product-shot-bg-repeat: no-repeat;
  --product-shot-bg-size: cover;

  /* 
    Footer
  */ 
  --footer-text-color: var(--neutral-900);
}