
label {
  & + div {
    margin-block-start: 8px;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: start;

  & div {
    position: relative;
    width: 100%;
    padding-inline-end: 0;
    border-radius: 12px;
    background: var(--neutral-200);
    border: 2px solid var(--neutral-200);

    &.text-field-focussed{
      border: 2px solid var(--brand-500);
    }

    & button{
      position: absolute;
      right: 0;
      top: 0;
      aspect-ratio: 1;
      height: 100%;
      box-shadow: none;
      border: none;
      background: none;
      outline: none;
      cursor:pointer;
      display: grid;
      place-items: center;
    }
  }

  &.wrapper-password{
    & div {
      padding-inline-end: 56px;
    }
  }
}

.text-field {
  width: 100%;
  height: 56px;
  padding: 16px;
  border-radius: 12px;
  background: transparent;
  border: none;
  outline: none;
}



.error-text{
  color: var(--error-500, #AD351F);
  font-family: var(--body-text-font-family);
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-block-start: 8px;
  margin-inline-start: 20px;
}