.wrapper {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100vh;
  max-height: 500px;
  
  @media (width > 812px) and (height < 429px), (width > 991px) and (height > 430px) {    height: 100%;
    max-height: 100%;
  }

  &.solid {
    background-color: var(--product-shot-background);
  }

  &.gradient {
    background: var(--product-shot-background);
  }
  
  &.image {
    background: var(--product-shot-background);
    background-position: var(--product-shot-bg-position);
    background-size: var(--product-shot-bg-size);
    background-repeat: no-repeat;
  }
}

.container{
  display: grid;
  place-items: center;
  width: 100%;
  max-width: 100%;
  flex: 1;
  padding-inline-start: 24px;
  padding-block-end: 24px;
  padding-inline-end: max(env(safe-area-inset-right),24px);

  @media (width >= 992px) {
    padding-block-end: 140px;
    padding-inline-start: 96px;
    padding-inline-end: 96px;
    height: calc(100vh - 140px);
  }

  & div {
    position: relative;
    height: 100%;
    width: 100%;

    @media (width >= 992px) {
      height: unset;
      height: calc(100vh - 280px);
      
    }

  }

  & img {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center center;
    overflow: hidden;
    position: absolute;

    @media (width >= 992px) {
      position: unset;
      max-height: 1320px;
    }
  }
}
