*,
*::before,
*::after{
  box-sizing: border-box;
}

html, body, #root {
  min-height: 100vh;
  background-color: var(--page-background-color);
}

html { font-size: 62.5%; } 

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
