@font-face {
  font-family: Bitterbrush;
  src: url("./fonts/Bitterbrush-Regular.otf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Nunito;
  src: url("./fonts/Nunito-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Nunito;
  src: url("./fonts/Nunito-Bold.ttf");
  font-weight: 600;
  font-style: normal;
}