.icon-button {
  text-decoration: inherit;
  display:inline-grid;
  place-items: center;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  color: var(--social-media-icon-color);
  background-color: var(--social-media-bg-color);
}

.icon-button-detailpage {
  color: var(--social-media-icon-color-detailpage);
  background-color: var(--social-media-bg-color-detailpage);
}