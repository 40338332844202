.container{
  height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: stretch;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex: 0 0 56px;
  padding-inline-start: max(env(safe-area-inset-left),16px);
  padding-inline-end: max(env(safe-area-inset-right),16px);

  @media (width >= 992px) {
    padding-inline-start: 96px;
    padding-inline-end: 96px;
  }

  @media (width > 812px) and (height < 429px), (width > 991px) and (height > 430px) {    flex-flow: row nowrap;
  }
}


.button{
  &:not(:last-child){
    margin-block-end: 16px;
  }

  & a {
    display: inline-flex;
  }
}

.content{
  flex: auto;

  & > div > div{
    grid-column: 1 /-1;
    text-align: center;

    @media (width > 812px) and (height < 429px), (width > 991px) and (height > 430px) {      grid-column: 4 /-4;
    }


    display:flex;
    flex-flow: row nowrap;
    align-items:center;

    & h2{
      margin-block-end: 8px;
    }

    & p, & ul, & ol{
      &:not(:last-child){
        margin-block-end: 24px;
      }
    }


    & a.inline {
      color: var(--neutral-600);
      font-size: 12px;
      line-height: 14px;
    }

    & .email{
      color: var(--neutral-600);
      display:inline-flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 4px;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;

      & a {
        color: var(--neutral-600);

      }
    }

    & > div{
      width: 100%;
    }
  }
}
