.header{
  flex: 0 0 60px;
  width: 100%;
  text-align: start;

  @media (width > 812px) and (height < 429px), (width > 991px) and (height > 430px) {
    flex: 0 0 calc(var(--logo-height) + 24px + 24px);
    padding-block-start: 24px;
    padding-block-end: 24px;
  }

  & div{
    display: inline-block;

    @media (width > 812px) and (height < 429px), (width > 991px) and (height > 430px) {
      height: var(--logo-height);
      width: auto;
    }
  }
  
  & a{
    display:inline-block;
    transform-origin: center center;
    
    @media (width > 812px) and (height < 429px), (width > 991px) and (height > 430px) {
      height: var(--logo-height);
      width: auto;
    }
  }

  & img {
    object-fit: contain;
    object-position: center center;
    max-height: 24px;

    @media (width > 812px) and (height < 429px), (width > 991px) and (height > 430px) {
      max-height: none;
      height: var(--logo-height);
      width: auto;
    }
  }
  
  & img:last-of-type {
    @media (width < 576px) {
      display: none;
    }
  }

  & img:first-of-type {
    @media (width > 576px) {
        display: none;
    }
  }
}
