.wrapper {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;

  @media (width > 812px) and (height < 429px), (width > 991px) and (height > 430px) {  
    flex-flow: row nowrap;
    min-height: 100vh;
  }

  & > div{
    width: 100%;

    @media (width > 812px) and (height < 429px), (width > 991px) and (height > 430px) {   
      flex: 0 0 50%;
      width: auto;
      align-self: stretch;
    }

    &:nth-child(2){
      order: -1;

      @media (width > 812px) and (height < 429px), (width > 991px) and (height > 430px) {
        order: 2;

      }
    }
  }
}

/* @media (width > 812px) and (height < 429px), (width > 991px) and (height > 430px) { */
        